import React from 'react';
import { Container, Paper, Button, Typography, Box, TextField, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)`
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    z-index: 1000;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
`;

const StyledLogo = styled('div')`
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 10px;
`;

const StyledNavList = styled('ul')`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
`;

const StyledNavItem = styled('li')`
    display: inline-block;
`;

const StyledNavLink = styled('a')`
    text-decoration: none;
    color: #333;
    font-weight: bold;
    padding: 10px;
    transition: color 0.3s ease;
`;

const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledTextField = styled(TextField)`
    width: 100%;
`;

const StyledSubmitButton = styled(Button)`
    align-self: flex-start;
`;

const Contact: React.FC = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Custom Header */}
            <StyledAppBar>
                <StyledToolbar>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledLogo>Quick, Inc.</StyledLogo>
                    </div>
                    <nav>
                        <StyledNavList>
                            <StyledNavItem><StyledNavLink href="#home">Home</StyledNavLink></StyledNavItem>
                            <StyledNavItem><StyledNavLink href="#about">About</StyledNavLink></StyledNavItem>
                            <StyledNavItem><StyledNavLink href="#services">Services</StyledNavLink></StyledNavItem>
                            <StyledNavItem><StyledNavLink href="#contact">Contact</StyledNavLink></StyledNavItem>
                        </StyledNavList>
                    </nav>
                </StyledToolbar>
            </StyledAppBar>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>
                <Container maxWidth="lg">
                    <Paper elevation={3} sx={{ p: 4 }}>
                        <Typography variant="h4" gutterBottom sx={{ color: 'black' }}>
                            Contact Us
                        </Typography>
                        <StyledForm>
                            <StyledTextField
                                label="Name"
                                variant="outlined"
                                required
                            />
                            <StyledTextField
                                label="Email"
                                variant="outlined"
                                required
                            />
                            <StyledTextField
                                label="Message"
                                variant="outlined"
                                multiline
                                rows={4}
                                required
                            />
                            <StyledSubmitButton variant="contained" color="primary">
                                Submit
                            </StyledSubmitButton>
                        </StyledForm>
                    </Paper>
                </Container>
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {new Date().getFullYear()} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

export default Contact;