import React from 'react';
import { Container, Paper, Typography, Box, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)`
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    z-index: 1000;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
`;

const StyledLogo = styled('div')`
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 10px;
`;

const StyledNavList = styled('ul')`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
`;

const StyledNavItem = styled('li')`
    display: inline-block;
`;

const StyledNavLink = styled('a')`
    text-decoration: none;
    color: #333;
    font-weight: bold;
    padding: 10px;
    transition: color 0.3s ease;
`;

const About: React.FC = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Custom Header */}
            <StyledAppBar>
                <StyledToolbar>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledLogo>Quick, Inc.</StyledLogo>
                    </div>
                    <nav>
                        <StyledNavList>
                            <StyledNavItem><StyledNavLink href="/">Home</StyledNavLink></StyledNavItem>
                            <StyledNavItem><StyledNavLink href="#about">About</StyledNavLink></StyledNavItem>
                            <StyledNavItem><StyledNavLink href="#services">Services</StyledNavLink></StyledNavItem>
                            <StyledNavItem><StyledNavLink href="#contact">Contact</StyledNavLink></StyledNavItem>
                        </StyledNavList>
                    </nav>
                </StyledToolbar>
            </StyledAppBar>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>
                <Container maxWidth="lg">
                    <Paper elevation={3} sx={{ p: 4, mt: 5 }}>
                        <Typography variant="h4" gutterBottom sx={{ color: 'black' }}>
                            会社代表情報
                        </Typography>
                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h5" sx={{ color: 'black' }}>
                                代表取締役社長/医師: 武田 淳宏
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'black', mt: 2 }}>
                                Quick, Inc.の創設者であり、現在代表取締役社長を務めています。IT業界で20年以上の経験を持ち、数々の革新的なプロジェクトを手掛けてきました。2024年設立からQuick, Inc.は急速に成長し、救急医療分野でのITソリューションの提供において業界をリードしています。
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'black', mt: 2 }}>
                                学歴等の情報.........
                            </Typography>
                        </Box>
                    </Paper>
                    <Paper elevation={3} sx={{ p: 4, mt: 5 }}>
                        <Typography variant="h4" gutterBottom sx={{ color: 'black' }}>
                            会社代表情報
                        </Typography>
                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h5" sx={{ color: 'black' }}>
                                CTO: 堤 敬信
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'black', mt: 2 }}>
                                Quick, Inc.の技術役員。（コメント......）
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'black', mt: 2 }}>
                                学歴等の情報.........
                            </Typography>
                        </Box>
                    </Paper>
                </Container>
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {new Date().getFullYear()} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

export default About;